var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"primary--text"},[_vm._v(" Procesar cobros ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.procesosCobranza,"outlined":"","label":"Seleccionar proceso de cobranza","return-object":"","dense":"","item-value":"procesoCobranzaConfigId","item-text":"descripcion"},model:{value:(_vm.procesoCobranzaSelected),callback:function ($$v) {_vm.procesoCobranzaSelected=$$v},expression:"procesoCobranzaSelected"}})],1)],1),(
          this.procesoCobranzaSelected != null &&
            (this.procesoCobranzaSelected.procesoCobranzaId == 1 ||
              this.procesoCobranzaSelected.procesoCobranzaId == 4)
        )?_c('v-row',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{ref:"file",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"dense":"","label":"Excel","placeholder":"Seleccionar el archivo Excel","accept":".xls,.xlsx,.csv","outlined":"","show-size":1000,"value":"","prepend-icon":"mdi-file-find-outline"},on:{"change":_vm.importExcel},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var text = ref.text;
return [_c('v-chip',{attrs:{"color":!_vm.loading ? 'primary' : 'grey lighten-1',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,2298716520),model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('v-col',{staticClass:"py-0 pt-6",attrs:{"cols":"3"}})],1):_vm._e(),(
          this.procesoCobranzaSelected != null &&
            this.procesoCobranzaSelected.procesoCobranzaId == 3
        )?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.buscarDeudaSIRO()}},model:{value:(_vm.isFormSIROValid),callback:function ($$v) {_vm.isFormSIROValid=$$v},expression:"isFormSIROValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha desde","outlined":"","dense":"","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","autocomplete":"off","rules":_vm.rules.required.concat([
                          _vm.rules.validDateRange(_vm.fechaDesdeSIRO, _vm.fechaHastaSIRO)
                        ])},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuDesdeSIRO = false},"blur":function($event){[
                          (_vm.fechaDesdeSIRO = _vm.parseDateToIso(
                            _vm.fechaDesdeTextFieldSIRO
                          ))
                        ]},"change":function($event){[
                          (_vm.fechaDesdeSIRO = _vm.parseDateToIso(
                            _vm.fechaDesdeTextFieldSIRO
                          ))
                        ]}},model:{value:(_vm.fechaDesdeTextFieldSIRO),callback:function ($$v) {_vm.fechaDesdeTextFieldSIRO=$$v},expression:"fechaDesdeTextFieldSIRO"}},'v-text-field',attrs,false),on))]}}],null,false,1979226899),model:{value:(_vm.menuDesdeSIRO),callback:function ($$v) {_vm.menuDesdeSIRO=$$v},expression:"menuDesdeSIRO"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaDesdeTextFieldSIRO = _vm.formatDate(_vm.fechaDesdeSIRO)},"input":function($event){_vm.menuDesdeSIRO = false}},model:{value:(_vm.fechaDesdeSIRO),callback:function ($$v) {_vm.fechaDesdeSIRO=$$v},expression:"fechaDesdeSIRO"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha hasta","append-icon":_vm.calendarIcon,"onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","hint":"Formato DD/MM/AAAA","clearable":"","dense":"","rules":_vm.rules.required.concat([
                          _vm.rules.validDateRange(_vm.fechaDesdeSIRO, _vm.fechaHastaSIRO)
                        ]),"outlined":"","autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuHastaSIRO = false},"blur":function($event){[
                          (_vm.fechaHastaSIRO = _vm.parseDateToIso(
                            _vm.fechaHastaTextFieldSIRO
                          ))
                        ]},"change":function($event){[
                          (_vm.fechaHastaSIRO = _vm.parseDateToIso(
                            _vm.fechaHastaTextFieldSIRO
                          ))
                        ]}},model:{value:(_vm.fechaHastaTextFieldSIRO),callback:function ($$v) {_vm.fechaHastaTextFieldSIRO=$$v},expression:"fechaHastaTextFieldSIRO"}},'v-text-field',attrs,false),on))]}}],null,false,3186510447),model:{value:(_vm.menuHastaSIRO),callback:function ($$v) {_vm.menuHastaSIRO=$$v},expression:"menuHastaSIRO"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaHastaTextFieldSIRO = _vm.formatDate(_vm.fechaHastaSIRO)},"input":function($event){_vm.menuHastaSIRO = false}},model:{value:(_vm.fechaHastaSIRO),callback:function ($$v) {_vm.fechaHastaSIRO=$$v},expression:"fechaHastaSIRO"}})],1)],1)],1)],1)],1)],1):_vm._e(),(
          this.procesoCobranzaSelected != null &&
            (this.procesoCobranzaSelected.procesoCobranzaId == 5 ||
              this.procesoCobranzaSelected.procesoCobranzaId == 6)
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-file-input',{ref:"fileTxt",attrs:{"disabled":_vm.loading || _vm.loadingProcesarBtn,"loading":_vm.loading,"dense":"","label":"Archivo","placeholder":"Seleccionar el archivo","accept":".txt","outlined":"","show-size":1000,"value":"","prepend-icon":"mdi-file-find-outline"},on:{"change":_vm.readTxtFormatFile},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var text = ref.text;
return [_c('v-chip',{attrs:{"color":!_vm.loading ? 'primary' : 'grey lighten-1',"dark":"","label":"","small":""}},[_vm._v(" "+_vm._s(text)+" ")])]}}],null,false,2298716520),model:{value:(_vm.fileTxt),callback:function ($$v) {_vm.fileTxt=$$v},expression:"fileTxt"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-form',{ref:"formTxtFormat",attrs:{"form":"formTxtFormat","id":"formTxtFormat"},on:{"submit":function($event){$event.preventDefault();return _vm.getDatos()}},model:{value:(_vm.isFormTxtValid),callback:function ($$v) {_vm.isFormTxtValid=$$v},expression:"isFormTxtValid"}},[_c('v-menu',{ref:"fecha-cobro-txt-format",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Fecha de cobro","outlined":"","dense":"","append-icon":_vm.calendarIcon,"hint":"Formato DD/MM/AAAA","onkeypress":"return (event.charCode >= 47 && event.charCode <= 57)","clearable":"","autocomplete":"off","rules":_vm.rules.required},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }_vm.menuFechaCobroTxtFormat = false},"blur":function($event){[
                      (_vm.fechaCobroTxtFormat = _vm.parseDateToIso(
                        _vm.fechaCobroTextfieldTxtFormat
                      ))
                    ]},"change":function($event){[
                      (_vm.fechaCobroTxtFormat = _vm.parseDateToIso(
                        _vm.fechaCobroTextfieldTxtFormat
                      ))
                    ]}},model:{value:(_vm.fechaCobroTextfieldTxtFormat),callback:function ($$v) {_vm.fechaCobroTextfieldTxtFormat=$$v},expression:"fechaCobroTextfieldTxtFormat"}},'v-text-field',attrs,false),on))]}}],null,false,1079664537),model:{value:(_vm.menuFechaCobroTxtFormat),callback:function ($$v) {_vm.menuFechaCobroTxtFormat=$$v},expression:"menuFechaCobroTxtFormat"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"change":function($event){_vm.fechaCobroTextfieldTxtFormat = _vm.formatDate(
                    _vm.fechaCobroTxtFormat
                  )},"input":function($event){_vm.menuFechaCobroTxtFormat = false}},model:{value:(_vm.fechaCobroTxtFormat),callback:function ($$v) {_vm.fechaCobroTxtFormat=$$v},expression:"fechaCobroTxtFormat"}})],1)],1)],1)],1):_vm._e(),_c('v-row',[_c('v-spacer'),_c('div',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[(
                this.procesoCobranzaSelected != null &&
                  this.procesoCobranzaSelected.procesoCobranzaId == 1
              )?_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","outlined":""},on:{"click":function($event){return _vm.exportExcelModelo()}}},[_vm._v(" descargar formato ")]):_vm._e()],1)],1),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.closeModalExcel()}}},[_vm._v(" Salir ")]),(
            this.procesoCobranzaSelected != null &&
              this.procesoCobranzaSelected.procesoCobranzaId == 3
          )?_c('v-btn',{staticClass:"to-right ml-4",attrs:{"color":"primary","disabled":!_vm.isFormSIROValid,"type":"submit","form":"form","loading":_vm.loadingProcesarBtn}},[_vm._v(" Procesar ")]):_vm._e(),(
            this.procesoCobranzaSelected != null &&
              (this.procesoCobranzaSelected.procesoCobranzaId == 5 ||
                this.procesoCobranzaSelected.procesoCobranzaId == 6)
          )?_c('v-btn',{staticClass:"to-right ml-4",attrs:{"color":"primary","disabled":!_vm.isFormTxtValid,"type":"submit","form":"formTxtFormat","loading":_vm.loadingProcesarBtn}},[_vm._v(" Procesar ")]):_vm._e()],1)],1)],1),(_vm.modalExportarExcel)?_c('v-dialog',{attrs:{"max-width":"46%","persistent":""},model:{value:(_vm.modalExportarExcel),callback:function ($$v) {_vm.modalExportarExcel=$$v},expression:"modalExportarExcel"}},[_c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v("Se generará un archivo con el detalle de los comprobantes")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"to-right",attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.exportExcelError()}}},[_vm._v(" Continuar ")])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }