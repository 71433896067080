<template>
  <v-card>
    <v-container>
      <v-card-title class="primary--text">
        Procesar cobros
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="py-0">
            <v-select
              v-model="procesoCobranzaSelected"
              :items="procesosCobranza"
              outlined
              label="Seleccionar proceso de cobranza"
              return-object
              dense
              item-value="procesoCobranzaConfigId"
              item-text="descripcion"
            >
            </v-select>
          </v-col>
        </v-row>
        <!-- Proceso de cobranza Gecros Estándar y Macro Link (Importador de excel)-->
        <v-row
          cols="12"
          v-if="
            this.procesoCobranzaSelected != null &&
              (this.procesoCobranzaSelected.procesoCobranzaId == 1 ||
                this.procesoCobranzaSelected.procesoCobranzaId == 4)
          "
          class="py-0"
        >
          <v-col cols="8">
            <v-file-input
              ref="file"
              v-model="file"
              :disabled="loading"
              :loading="loading"
              dense
              label="Excel"
              placeholder="Seleccionar el archivo Excel"
              accept=".xls,.xlsx,.csv"
              outlined
              :show-size="1000"
              @change="importExcel"
              value=""
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="3" class="py-0 pt-6"> </v-col>
        </v-row>
        <!-- Proceso de cobranza SIRO VIA API -->
        <v-row
          v-if="
            this.procesoCobranzaSelected != null &&
              this.procesoCobranzaSelected.procesoCobranzaId == 3
          "
        >
          <v-col cols="12" class="py-0">
            <v-form
              v-model="isFormSIROValid"
              ref="form"
              form="form"
              id="form"
              @submit.prevent="buscarDeudaSIRO()"
            >
              <v-row>
                <v-col cols="12" sm="4" class="py-0">
                  <v-menu
                    v-model="menuDesdeSIRO"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaDesdeTextFieldSIRO"
                        label="Fecha desde"
                        outlined
                        dense
                        :append-icon="calendarIcon"
                        v-mask="'##/##/####'"
                        hint="Formato DD/MM/AAAA"
                        @keydown.tab="menuDesdeSIRO = false"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        @blur="
                          [
                            (fechaDesdeSIRO = parseDateToIso(
                              fechaDesdeTextFieldSIRO
                            ))
                          ]
                        "
                        @change="
                          [
                            (fechaDesdeSIRO = parseDateToIso(
                              fechaDesdeTextFieldSIRO
                            ))
                          ]
                        "
                        clearable
                        autocomplete="off"
                        :rules="
                          rules.required.concat([
                            rules.validDateRange(fechaDesdeSIRO, fechaHastaSIRO)
                          ])
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="fechaDesdeSIRO"
                      no-title
                      scrollable
                      @change="
                        fechaDesdeTextFieldSIRO = formatDate(fechaDesdeSIRO)
                      "
                      @input="menuDesdeSIRO = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" class="py-0">
                  <v-menu
                    v-model="menuHastaSIRO"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="fechaHastaTextFieldSIRO"
                        label="Fecha hasta"
                        :append-icon="calendarIcon"
                        @keydown.tab="menuHastaSIRO = false"
                        v-mask="'##/##/####'"
                        onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                        hint="Formato DD/MM/AAAA"
                        @blur="
                          [
                            (fechaHastaSIRO = parseDateToIso(
                              fechaHastaTextFieldSIRO
                            ))
                          ]
                        "
                        @change="
                          [
                            (fechaHastaSIRO = parseDateToIso(
                              fechaHastaTextFieldSIRO
                            ))
                          ]
                        "
                        clearable
                        dense
                        :rules="
                          rules.required.concat([
                            rules.validDateRange(fechaDesdeSIRO, fechaHastaSIRO)
                          ])
                        "
                        outlined
                        autocomplete="off"
                        v-bind="attrs"
                        v-on="on"
                      >
                      </v-text-field>
                    </template>

                    <v-date-picker
                      v-model="fechaHastaSIRO"
                      no-title
                      scrollable
                      @change="
                        fechaHastaTextFieldSIRO = formatDate(fechaHastaSIRO)
                      "
                      @input="menuHastaSIRO = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <!-- Proceso de cobranza Supervielle -->
        <v-row
          v-if="
            this.procesoCobranzaSelected != null &&
              (this.procesoCobranzaSelected.procesoCobranzaId == 5 ||
                this.procesoCobranzaSelected.procesoCobranzaId == 6)
          "
        >
          <v-col cols="8">
            <v-file-input
              ref="fileTxt"
              v-model="fileTxt"
              :disabled="loading || loadingProcesarBtn"
              :loading="loading"
              dense
              label="Archivo"
              placeholder="Seleccionar el archivo"
              accept=".txt"
              outlined
              :show-size="1000"
              @change="readTxtFormatFile"
              value=""
              prepend-icon="mdi-file-find-outline"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  :color="!loading ? 'primary' : 'grey lighten-1'"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
          </v-col>
          <v-col cols="4">
            <v-form
              v-model="isFormTxtValid"
              ref="formTxtFormat"
              form="formTxtFormat"
              id="formTxtFormat"
              @submit.prevent="getDatos()"
            >
              <v-menu
                ref="fecha-cobro-txt-format"
                v-model="menuFechaCobroTxtFormat"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fechaCobroTextfieldTxtFormat"
                    label="Fecha de cobro"
                    outlined
                    dense
                    :append-icon="calendarIcon"
                    v-mask="'##/##/####'"
                    hint="Formato DD/MM/AAAA"
                    @keydown.tab="menuFechaCobroTxtFormat = false"
                    onkeypress="return (event.charCode >= 47 && event.charCode <= 57)"
                    @blur="
                      [
                        (fechaCobroTxtFormat = parseDateToIso(
                          fechaCobroTextfieldTxtFormat
                        ))
                      ]
                    "
                    @change="
                      [
                        (fechaCobroTxtFormat = parseDateToIso(
                          fechaCobroTextfieldTxtFormat
                        ))
                      ]
                    "
                    clearable
                    autocomplete="off"
                    :rules="rules.required"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="fechaCobroTxtFormat"
                  no-title
                  scrollable
                  @change="
                    fechaCobroTextfieldTxtFormat = formatDate(
                      fechaCobroTxtFormat
                    )
                  "
                  @input="menuFechaCobroTxtFormat = false"
                ></v-date-picker>
              </v-menu>
            </v-form>
          </v-col>
          <!-- <ReadSupervielleTxtFile
              v-on="$listeners"
              :session="session"
              :entFinId="entFinId"
              :procesoCobranzaConfigId="procesoCobranzaConfigId"
              @closeModalExport="closeModalExcel()"
            /> -->
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <div>
            <v-col cols="12" class="py-0">
              <v-btn
                v-if="
                  this.procesoCobranzaSelected != null &&
                    this.procesoCobranzaSelected.procesoCobranzaId == 1
                "
                color="primary"
                class="to-right"
                outlined
                @click="exportExcelModelo()"
              >
                descargar formato
              </v-btn></v-col
            >
          </div>
          <v-btn outlined @click="closeModalExcel()"> Salir </v-btn>
          <v-btn
            color="primary"
            :disabled="!isFormSIROValid"
            class="to-right ml-4"
            type="submit"
            form="form"
            :loading="loadingProcesarBtn"
            v-if="
              this.procesoCobranzaSelected != null &&
                this.procesoCobranzaSelected.procesoCobranzaId == 3
            "
          >
            Procesar
          </v-btn>
          <v-btn
            color="primary"
            :disabled="!isFormTxtValid"
            class="to-right ml-4"
            type="submit"
            form="formTxtFormat"
            :loading="loadingProcesarBtn"
            v-if="
              this.procesoCobranzaSelected != null &&
                (this.procesoCobranzaSelected.procesoCobranzaId == 5 ||
                  this.procesoCobranzaSelected.procesoCobranzaId == 6)
            "
          >
            Procesar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-container>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los comprobantes</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcelError()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import helpersExcel from "@/utils/helpers/importExportExcel";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";
import { mask } from "vue-the-mask";

export default {
  name: "ProcesosDeCobranza",
  props: {
    session: { type: Number, required: false, default: null }
  },
  directives: { mask },
  data() {
    return {
      calendarIcon: enums.icons.CALENDAR,
      rules: rules,
      procesosCobranza: [],
      procesoCobranzaSelected: null,
      loading: false,
      loadingProcesarBtn: false,
      entFinId: null,
      comprobantesAGenerar: [],
      excelData: [],
      modalExportarExcel: false,
      archivoEnProceso: false,
      file: null,
      isFormSIROValid: false,
      fechaDesdeSIRO: null,
      fechaDesdeTextFieldSIRO: null,
      menuDesdeSIRO: false,
      fechaHastaSIRO: null,
      fechaHastaTextFieldSIRO: null,
      menuHastaSIRO: false,
      fileTxt: null,
      isFormTxtValid: false,
      contentTxtToArray: null,
      fechaCobroTxtFormat: null,
      menuFechaCobroTxtFormat: false,
      fechaCobroTextfieldTxtFormat: null
    };
  },
  created() {
    this.getProcesos();
  },
  methods: {
    ...mapActions({
      getProcesosCobranza: "caja/getProcesosCobranza",
      getDeudaSIROAPI: "caja/getDeudaSIROAPI",
      postComprobantesGecrosEstandar: "caja/postComprobantes",
      postComprobantesDebitoSupervielle:
        "caja/postComprobantesDebitoSupervielle",
      postComprobantesDebitoSupervielle:
        "caja/postComprobantesDebitoSupervielle",
      postComprobantesMacro: "caja/postComprobantesMacro",
      generarComprobantesDeudaCobro: "caja/generarComprobantesDeudaCobro",
      postComprobantesCredicoop: "caja/postComprobantesCredicoop",
      setAlert: "user/setAlert"
    }),
    async getProcesos() {
      const response = await this.getProcesosCobranza();
      this.procesosCobranza = response;
    },
    closeModalExcel() {
      this.$emit("toggleModalProcesoDeCobranza");
    },
    // metodos de datetimes
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateToIso(date) {
      if (!date) return null;
      if (date.length === 10) {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
    },
    // metodo para insertar los comprobantes al ABM principal
    async insertComprobantes(response) {
      this.comprobantesError = response.comprobantesError;
      this.comprobantesOk = response.comprobantesOk;
      if (this.comprobantesOk.length > 0) {
        const datos = {
          comprobantesDeuda: [],
          datosComprobanteCobro: {},
          comprobantesByProceso: []
        };
        this.comprobantesOk.forEach(comprobante => {
          datos.comprobantesByProceso.push({
            //comprobantesOk es un array de objetos, hay que recorrerlo para que setear esos datos
            procId: comprobante.procId,
            compId: comprobante.compId,
            procesoCobranzaConfigId:
              this.procesoCobranzaSelected.procesoCobranzaId == 1
                ? 0
                : this.procesoCobranzaSelected.procesoCobranzaConfigId
          });
        });
        try {
          this.comprobantesAGenerar = await this.generarComprobantesDeudaCobro({
            sessionId: this.session,
            params: datos,
            esProceso: true
          });
          this.loading = false;
          this.$emit("setComprobantesCobros", this.comprobantesAGenerar);
        } catch {
          this.loading = false;
        }
      }
      if (this.comprobantesError.length > 0) {
        this.modalExportarExcel = true;
      } else {
        this.closeModalExcel();
      }
    },
    // metodo de importador de excel
    // ProcesoCobranzaId : 1	Gecros
    // ProcesoCobranzaId : 2	Prueba
    // ProcesoCobranzaId : 3	SIRO VIA API
    // ProcesoCobranzaId : 4	Macro Link de pago
    // ProcesoCobranzaId : 5	Débito Supervielle
    async importExcel() {
      if (this.file) {
        try {
          // Este condicional detalla las filas a ignorar a la hora de importar el excel
          // En este caso el excel de macro hay que ignorar 1 row, si no, por defecto no ignora ninguna
          if (this.procesoCobranzaSelected.procesoCobranzaId == 4) {
            this.getArchivo(1);
          } else {
            this.getArchivo(0);
          }
          // el archivo tarda en cargar, el metodo contempla si se cargo correctamente
          const archivoValido = this.verifArchivo();
          if (archivoValido) {
            let response = null;
            let excelDataTranslated = [];
            let request = {};
            switch (this.procesoCobranzaSelected.procesoCobranzaId) {
              case 1:
                // this.excelData[0].filas es el array de objetos con la informacion del excel
                this.excelData[0].filas.forEach(x => {
                  excelDataTranslated.push({
                    agenteCuenta: x["AGENTE DE CUENTA"],
                    fechaDeCobro: helpersExcel.translateDate(
                      x["FECHA DE COBRO"]
                    ),
                    importeCobrado: x["IMPORTE COBRADO"],
                    formaDePago: x["FORMA DE PAGO"],
                    tipoComprobante: x["TIPO DE COMPROBANTE"],
                    caja: x["CAJA"],
                    sucursal: x["SUCURSAL"],
                    tipoComprobanteDeuda: x["TIPO DE COMPROBANTE DEUDA"],
                    sucursalDeuda: x["SUCURSAL DEUDA"],
                    numero: x.Numero,
                    compId: x.CompId,
                    procesocobranzaconfigId: this.procesoCobranzaSelected
                      .procesoCobranzaConfigId
                  });
                });
                request = {
                  filas: excelDataTranslated,
                  nombreArchivo: this.file.name
                };
                response = await this.postComprobantesGecrosEstandar(request);
                break;
              case 4:
                this.excelData[0].filas.forEach(x => {
                  excelDataTranslated.push({
                    FechaCobro:
                      x["Fecha Pago"]?.length >= 10
                        ? x["Fecha Pago"].substring(0, 10)
                        : x["Fecha Pago"],
                    ImporteCobrado: x["Monto Neto"],
                    CompId: x["ID ComercioTransaccion"]
                  });
                });
                request = {
                  entFinId: this.procesoCobranzaSelected.entFinId,
                  procesoCobranzaConfigId: this.procesoCobranzaSelected
                    .procesoCobranzaConfigId,
                  data: excelDataTranslated
                };
                response = await this.postComprobantesMacro(request);
            }
            this.insertComprobantes(response);
          } else {
            // en el caso de que no cargó, ejecuto de nuevo la funcion dentro de 2 segundos
            setTimeout(this.importExcel, 2000);
          }
        } catch (error) {
          // contempla el error de formato dentro del importExportExcel.js
          this.loading = false;
          this.setAlert({ type: "warning", message: error });
          this.$refs["file"].reset();
        }
      }
    },
    getArchivo(rowsToIgnore) {
      // si el archivoEnProceso es false, significa que es la primera vez que llama al metodo
      if (this.archivoEnProceso == false) {
        this.loading = true;
        this.archivoEnProceso = true;
        this.$refs["file"].validate(true);
        if (this.$refs.file.validate()) {
          this.excelData = helpersExcel.excelImport(this.file, rowsToIgnore);
        }
      }
    },
    verifArchivo() {
      if (this.excelData[0]?.filas != undefined) {
        this.archivoEnProceso = false;
        return true;
      }
      return false;
    },
    exportExcelError() {
      let result = [];
      this.comprobantesError.forEach(x =>
        result.push({
          ["Agente de cuenta"]: x.agenteCuenta,
          ["Fecha de cobro"]: x.fechaDeCobro,
          ["Importe cobrado"]: x.importeCobrado,
          ["Forma de pago"]: x.formaDePago,
          ["Tipo de comprobante"]: x.tipoComprobante,
          ["Caja"]: x.caja,
          ["Sucursal"]: x.sucursal,
          ["Tipo de comprobante deuda"]: x.tipoComprobanteDeuda,
          ["Sucursal deuda"]: x.sucursalDeuda,
          ["Numero"]: x.numero,
          ["CompId"]: x.compId,
          ["Error"]: x.mensajeError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Errores comprobantes");
      this.closeModalExcel();
    },
    // metodos gecros estandar
    exportExcelModelo() {
      let result = [];
      result.push({
        ["AGENTE DE CUENTA"]: "170300",
        ["FECHA DE COBRO"]: "25/05/2022",
        ["IMPORTE COBRADO"]: "1525,55",
        ["FORMA DE PAGO"]: "Contado",
        ["TIPO DE COMPROBANTE"]: "Factura A",
        ["CAJA"]: "Caja 1",
        ["SUCURSAL"]: "9999",
        ["TIPO DE COMPROBANTE DEUDA"]: "Cupon de deuda",
        ["SUCURSAL DEUDA"]: "9999",
        ["Numero"]: "1702",
        ["CompId"]: "Dato opcional"
      });
      let formato = {
        filas: result,
        hoja: "Modelo"
      };
      helpersExcel.excelExport(formato, "Gecros modelo");
    },
    // metodos siro via api
    async buscarDeudaSIRO() {
      this.loadingProcesarBtn = true;
      const data = {
        fecha_desde: this.fechaDesdeSIRO,
        fecha_hasta: this.fechaHastaSIRO,
        entFinId: this.procesoCobranzaSelected.entFinId,
        procesoCobranzaConfigId: this.procesoCobranzaSelected
          .procesoCobranzaConfigId
      };
      try {
        this.responseSiro = await this.getDeudaSIROAPI(data);
        this.insertComprobantes(this.responseSiro);
      } catch {
        this.loadingProcesarBtn = false;
      }
    },
    // metodos txt
    readTxtFormatFile() {
      if (this.fileTxt) {
        this.loading = true;
        const reader = new FileReader();
        if (this.fileTxt.name.toLowerCase().includes(".txt")) {
          reader.onload = res => {
            this.content = res.target.result;
            this.contentTxtToArray = this.content.toString().split("\n");
          };
          reader.onerror = err => console.log(err);
          reader.readAsText(this.fileTxt);
        } else {
          reader.onload = res => {
            this.content = res.target.result;
            this.contentTxtToArray = this.content.toString().split("\n");
          };
          reader.onerror = err => console.log(err);
          reader.readAsText(this.fileTxt);
        }
        this.loading = false;
      }
    },
    async getDatos() {
      this.loadingProcesarBtn = true;
      let data = [];
      this.contentTxtToArray.forEach(x => {
        data.push({
          datos: x
        });
      });

      const request = {
        entFinId: this.procesoCobranzaSelected.entFinId,
        procesoCobranzaConfigId: this.procesoCobranzaSelected
          .procesoCobranzaConfigId,
        fechaCobro: this.fechaCobroTextfieldTxtFormat,
        data: data
      };
      try {
        switch (this.procesoCobranzaSelected.procesoCobranzaId) {
          case 5:
            const responseSupervielle = await this.postComprobantesDebitoSupervielle(
              request
            );
            this.insertComprobantes(responseSupervielle);
            this.loadingProcesarBtn = false;
            break;
          case 6:
            const responseCredicoop = await this.postComprobantesCredicoop(
              request
            );
            this.insertComprobantes(responseCredicoop);
            this.loadingProcesarBtn = false;
            break;
          default:
            break;
        }
      } catch {
        this.loadingProcesarBtn = false;
      }
    }
  }
};
</script>
