<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-5 pb-0"
      >
        <PageHeader :title="title" />
        <v-card class="mx-auto my-12 mt-2 mb-4 pt-0" max-width="1200" outlined>
          <v-container class="pt-0">
            <v-row justify="end">
              <v-col
                col="9"
                class="pt-0 pb-0"
                align="start"
                align-self="center"
              >
                <v-btn-toggle dense v-model="text" color="primary" group>
                  <v-btn
                    value="BuscarDeuda"
                    @click="switchTab(1)"
                    v-if="canBuscarDeuda"
                    :disabled="buscarDeudaTabActivate"
                  >
                    Buscar deuda
                  </v-btn>
                  <v-btn
                    value="ProcesarCobros"
                    @click="switchTab(2)"
                    v-if="canProcesarCobros"
                    :disabled="registrosCobros.length > 0"
                  >
                    Procesar cobros
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col
                cols="1"
                align-self="center"
                align="center"
                class="pr-0 pl-0"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="toggleFiltersSelected"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                align="left"
                class="pt-0 pb-0"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
            </v-row>

            <v-expand-transition mode="out-in">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <!-- Entidad facturante -->
                  <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    class="py-0"
                    v-if="tabBuscarDeuda"
                  >
                    <v-autocomplete
                      v-model="entidadFacturanteSelected"
                      :items="entidadesFacturantes"
                      label="Entidad facturante (*)"
                      item-text="value"
                      item-value="id"
                      :rules="rules.required"
                      :disabled="registrosCobros.length > 0"
                      outlined
                      clearable
                      @change="disableFilters()"
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Tipo de comprobante -->
                  <v-col
                    cols="12"
                    md="4"
                    sm="12"
                    class="py-0"
                    v-if="tabBuscarDeuda"
                  >
                    <v-autocomplete
                      v-model="tipoComprobanteSelected"
                      :items="tiposComprobantes"
                      label="Tipo de comprobante"
                      :rules="enabledFiltersComprobantes ? rules.required : []"
                      item-text="value"
                      @change="disableFilters()"
                      clearable
                      item-value="id"
                      outlined
                      dense
                      :disabled="!enabledFiltersComprobantes"
                    >
                    </v-autocomplete>
                  </v-col>
                  <!-- Sucursal -->
                  <v-col
                    cols="12"
                    md="2"
                    sm="12"
                    class="py-0"
                    v-if="tabBuscarDeuda"
                  >
                    <v-text-field
                      v-model="sucursal"
                      label="Sucursal"
                      outlined
                      dense
                      :rules="
                        enabledFiltersComprobantes
                          ? rules.required.concat(
                              rules.positiveNumberHigherThanCero
                            )
                          : []
                      "
                      v-mask="'####'"
                      :disabled="!enabledFiltersComprobantes"
                    ></v-text-field>
                  </v-col>
                  <!-- Número de comprobante -->
                  <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    class="py-0"
                    v-if="tabBuscarDeuda"
                  >
                    <v-text-field
                      v-model="nroComprobante"
                      label="N° de comprobante"
                      outlined
                      dense
                      :disabled="!enabledFiltersComprobantes"
                      v-mask="'########'"
                      :rules="enabledFiltersComprobantes ? rules.required : []"
                    ></v-text-field>
                  </v-col>
                  <!-- Número de lote -->
                  <v-col
                    cols="12"
                    md="3"
                    sm="12"
                    class="py-0"
                    v-if="tabBuscarDeuda"
                  >
                    <v-text-field
                      ref="nro-lote"
                      v-model="nroLote"
                      label="N° de lote"
                      v-mask="'########'"
                      outlined
                      @keyup="disableFilters()"
                      :disabled="!enabledFilterNroLote"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="end">
                  <v-col cols="12" md="4" sm="6" class="pb-0 pt-5 text-right">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      color="primary"
                      :disabled="!isFormValid"
                      elevation="2"
                      small
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            item-key="compId"
            :items="registrosCobros"
            :loading="loading"
            class="elevation-1"
            :search="search"
            loading-text="Cargando datos..."
          >
            <template v-slot:[`item.agectaNom`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.agectaNom }}</span
              >
              <span v-else> {{ item.agectaNom }} </span>
            </template>
            <template v-slot:[`item.fechaCobro`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.fechaCobro }}</span
              >
              <span v-else> {{ item.fechaCobro }} </span>
            </template>
            <template v-slot:[`item.totalComprobante`]="{ item }">
              <span
                v-if="!item.numeradorAutomatico"
                class="invalidConfigText"
                >{{ formatPrice(item.totalComprobante) }}</span
              >
              <span v-else> {{ formatPrice(item.totalComprobante) }} </span>
            </template>
            <template v-slot:[`item.formaPagoNom`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.formaPagoNom }}</span
              >
              <span v-else> {{ item.formaPagoNom }} </span>
            </template>
            <template v-slot:[`item.tipoCompNom`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.tipoCompNom }}</span
              >
              <span v-else> {{ item.tipoCompNom }} </span>
            </template>
            <template v-slot:[`item.cajaNom`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.cajaNom }}</span
              >
              <span v-else> {{ item.cajaNom }} </span>
            </template>
            <template v-slot:[`item.sucursal`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.sucursal }}</span
              >
              <span v-else> {{ item.sucursal }} </span>
            </template>
            <template v-slot:[`item.datoComprobanteDeuda`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.datoComprobanteDeuda }}</span
              >
              <span v-else> {{ item.datoComprobanteDeuda }} </span>
            </template>
            <template v-slot:[`item.saldo`]="{ item }">
              <span
                v-if="!item.numeradorAutomatico"
                class="invalidConfigText"
                >{{ formatPrice(item.saldo) }}</span
              >
              <span v-else> {{ formatPrice(item.saldo) }} </span>
            </template>
            <template v-slot:[`item.vencimiento`]="{ item }">
              <span v-if="!item.numeradorAutomatico" class="invalidConfigText">
                {{ item.vencimiento }}</span
              >
              <span v-else> {{ item.vencimiento }} </span>
            </template>
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="9">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3" align="end">
                    <v-btn
                      color="primary"
                      small
                      class="no-upper-case to-right"
                      v-model="deleteAllComps"
                      :loading="loadingDeleteAllBtn"
                      @click="deleteAllComprobantes()"
                      :disabled="
                        registrosCobros.length === 0 || loadingDeleteAllBtn
                      "
                    >
                      Eliminar todos
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="quitarComprobante(item.compId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Quitar comprobante</span>
              </v-tooltip>
            </template>
            <!-- ToDo cambiar por una mejor práctica(https://codepen.io/sunhao1256/pen/MWeZyMe) -->
            <template v-slot:[`header`]="">
              <thead>
                <tr>
                  <th colspan="" style="text-align: right">|</th>
                  <th colspan="5" class="fontColor" style="text-align: center">
                    Datos del cobro
                  </th>
                  <th colspan="" style="text-align: right">|</th>
                  <th colspan="3" class="fontColor" style="text-align: center">
                    Datos de la deuda
                  </th>
                  <th colspan="" style="text-align: left">|</th>
                </tr>
              </thead>
            </template>
          </v-data-table>
        </v-card>
        <v-row>
          <v-col cols="9" class="pl-5" v-if="disabledGenerarCobranzaBtn">
            <p class="invalidConfigText">
              Los comprobantes en color rojo no tienen numerador automático.
              Eliminarlos de la lista para continuar.
            </p>
          </v-col>
          <v-col
            :cols="disabledGenerarCobranzaBtn ? '3' : '12'"
            align="end"
            class="pl-0"
          >
            <v-btn
              color="primary"
              class="to-right"
              :disabled="
                disabledGenerarCobranzaBtn ||
                  registrosCobros.length === 0 ||
                  loadingGenerarCobranzaBtn
              "
              @click="generarCobranza()"
              :loading="loadingGenerarCobranzaBtn"
            >
              Generar cobranza
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="text-right pt-3 px-0"
        v-if="showIcon"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="modalBuscarDeuda"
      v-model="modalBuscarDeuda"
      max-width="80%"
      persistent
      @keydown.esc="toggleModalBuscarDeuda"
    >
      <BuscarDeudaModal
        :comprobantesDeuda="comprobantesDeuda"
        :session="session"
        @setComprobantesCobros="setComprobantesCobros($event)"
        @toggleModalBuscarDeuda="toggleModalBuscarDeuda"
      ></BuscarDeudaModal>
    </v-dialog>

    <v-dialog
      v-if="modalProcesoDeCobranza"
      v-model="modalProcesoDeCobranza"
      max-width="60%"
      persistent
      @keydown.esc="toggleModalProcesoDeCobranza"
    >
      <ProcesosDeCobranza
        :session="session"
        @setComprobantesCobros="setComprobantesCobros($event)"
        :registrosCobros="registrosCobros"
        @toggleModalProcesoDeCobranza="toggleModalProcesoDeCobranza"
      ></ProcesosDeCobranza>
    </v-dialog>
    <v-dialog
      v-if="modalExportarExcel"
      v-model="modalExportarExcel"
      max-width="46%"
      persistent
    >
      <v-card>
        <v-container>
          <v-card-title>
            <span class="pl-1 primary--text"
              >Se generará un archivo con el detalle de los comprobantes</span
            >
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="to-right"
              text
              @click="exportExcel()"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <DeleteDialog
      :maxWidth="'25%'"
      :titleProp="titleDelete"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmQuitarComprobante()"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/components/ui/PageHeader";
import enums from "@/utils/enums/index.js";
import FiltersSelected from "@/components/shared/FiltersSelected";
import DeleteDialog from "@/components/shared/DeleteDialog";
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import BuscarDeudaModal from "@/components/modules/cuotas/caja/BuscarDeudaModal.vue";
import ProcesosDeCobranza from "@/components/modules/cuotas/caja/ProcesosDeCobranza.vue";
import Ayuda from "@/components/shared/Ayuda.vue";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "ProcesarCobranzas",
  components: {
    PageHeader,
    FiltersSelected,
    BuscarDeudaModal,
    DeleteDialog,
    ProcesosDeCobranza,
    Ayuda
  },
  directives: { mask },
  created() {
    this.setEntidadesFacturantes();
    this.getSession();
  },
  data() {
    return {
      title: enums.titles.PROCESAR_COBRANZAS,
      text: "BuscarDeuda",
      tabBuscarDeuda: true,
      tabProcesarCobros: false,
      modalExportarExcel: false,
      registrosCobros: [],
      filtersApplied: [],
      showExpand: false,
      showHelp: false,
      optionCode: enums.webSiteOptions.PROCESAR_COBRANZAS,
      showIcon: true,
      showFilters: true,
      deleteIcon: enums.icons.DELETE,
      loadingDeleteAllBtn: false,
      clearFiltersIcon: enums.icons.CLEAR_FILTERS,
      closeFiltersIcon: enums.icons.CLOSE_FILTERS,
      isFormValid: false,
      loading: false,
      search: "",
      loadingGenerarCobranzaBtn: false,
      deleteAllComps: false,
      searchIcon: enums.icons.SEARCH,
      headers: [
        {
          text: "Agente de cuenta",
          value: "agectaNom",
          sortable: false,
          align: "start"
        },
        {
          text: "Fecha de cobro",
          value: "fechaCobro",
          sortable: false
        },
        {
          text: "Importe",
          value: "totalComprobante",
          sortable: false,
          align: "end"
        },
        {
          text: "Forma de pago",
          value: "formaPagoNom",
          sortable: false
        },
        {
          text: "Tipo de comprobante",
          value: "tipoCompNom",
          sortable: false
        },
        {
          text: "Caja",
          value: "cajaNom",
          sortable: false
        },
        {
          text: "Sucursal",
          value: "sucursal",
          sortable: false
        },
        {
          text: "Datos del comprobante",
          value: "datoComprobanteDeuda",
          sortable: false
        },
        {
          text: "Saldo",
          value: "saldo",
          sortable: false,
          align: "end"
        },
        {
          text: "Vencimiento",
          value: "vencimiento",
          sortable: false
        },
        { text: "Acciones", value: "actions", sortable: false, align: "end" }
      ],
      rules: rules,
      entidadFacturanteSelected: null,
      entidadesFacturantes: [],
      nroLote: null,
      tipoComprobanteSelected: null,
      tiposComprobantes: [],
      sucursal: null,
      nroComprobante: null,
      enabledFiltersComprobantes: true,
      enabledFilterNroLote: true,
      comprobantesDeuda: [],
      modalBuscarDeuda: false,
      modalProcesoDeCobranza: false,
      session: null,
      titleDelete: "",
      showDeleteModal: false,
      disabledGenerarCobranzaBtn: false,
      resultsExportExcel: [],
      canBuscarDeuda: false,
      canProcesarCobros: false,
      buscarDeudaTabActivate: true
    };
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      getCompCtaCteAndTipo: "configuracion/getCompCtaCteAndTipo",
      getComprobantesDeudasByFilters: "caja/getComprobantesDeudasByFilters",
      getSessionCobroMasivo: "caja/getSessionCobroMasivo",
      generarComprobantePago: "caja/generarComprobantePago",
      deleteCompRegistro: "caja/deleteCompRegistro",
      deleteAllCompsRegistro: "caja/deleteAllCompsRegistro",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.BUSCAR_DEUDA:
            this.canBuscarDeuda = true;
            break;
          case enums.modules.adminSistema.permissions.PROCESAR_COBROS:
            this.canProcesarCobros = true;
            break;
          default:
            break;
        }
      });
    },
    async setEntidadesFacturantes() {
      const entsFacts = await this.getEntidadesFacturantes();
      this.entidadesFacturantes = entsFacts;
      if (this.entidadesFacturantes.length === 1)
        this.entidadFacturanteSelected = this.entidadesFacturantes[0].id;
    },
    async getSession() {
      const session = await this.getSessionCobroMasivo();
      this.session = session.session;
    },
    switchTab(num) {
      this.registrosCobros = [];
      this.resetForm();
      this.filtersApplied = [];
      if (this.showFilters === false) this.showFilters = true;
      switch (num) {
        case 1:
          this.tabBuscarDeuda = true;
          this.buscarDeudaTabActivate = true;
          this.modalProcesoDeCobranza = false;
          break;
        case 2:
          this.tabBuscarDeuda = false;
          this.modalProcesoDeCobranza = true;
          this.buscarDeudaTabActivate = false;
          this.tabProcesarCobros = false;
          break;
        default:
          break;
      }
    },
    resetForm() {
      if (this.registrosCobros.length === 0)
        this.entidadFacturanteSelected = null;
      this.tiposComprobantes = [];
      this.tipoComprobanteSelected = null;
      this.sucursal = null;
      this.nroComprobante = null;
      this.nroLote = null;
      this.enabledFiltersComprobantes = true;
      this.enabledFilterNroLote = true;
    },
    async applyFilters() {
      this.loading = true;
      this.customizeFiltersApplied();
      const filters = {
        entFacId: this.entidadFacturanteSelected,
        tipoComprobanteId: this.tipoComprobanteSelected,
        sucursalId: this.sucursal,
        nroComprobante: this.nroComprobante,
        nroLote: this.nroLote
      };
      if (this.tabBuscarDeuda) {
        try {
          const response = await this.getComprobantesDeudasByFilters(filters);
          this.comprobantesDeuda = response;
          this.modalBuscarDeuda = true;
        } catch {
          this.loading = false;
        }
      }
    },
    customizeFiltersApplied() {
      // lo quito y agrego manualmente ya que el componente de filtros lo toma como undefined
      this.filtersApplied = [];
      if (this.entidadFacturanteSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "entidadFacturanteSelected",
          label: "Entidad facturante",
          model: this.entidadFacturanteSelected
        });
      }
      if (this.tipoComprobanteSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "tipoComprobanteSelected",
          label: "Tipo de comprobante",
          model: this.tipoComprobanteSelected
        });
      }
      if (this.sucursal) {
        this.filtersApplied.splice(2, 1, {
          key: "sucursal",
          label: "Sucursal",
          model: this.sucursal
        });
      }
      if (this.nroComprobante) {
        this.filtersApplied.splice(3, 1, {
          key: "nroComprobante",
          label: "N° de comprobante",
          model: this.nroComprobante
        });
      }
      if (this.nroLote) {
        this.filtersApplied.splice(4, 1, {
          key: "nroLote",
          label: "N° de lote",
          model: this.nroLote
        });
      }
    },
    async disableFilters() {
      if (this.entidadFacturanteSelected != null) {
        if (this.tipoComprobanteSelected != null) {
          this.enabledFilterNroLote = false;
          this.nroLote = null;
        } else {
          this.enabledFilterNroLote = true;
        }
        const tiposComp = await this.getCompCtaCteAndTipo(
          this.entidadFacturanteSelected
        );
        this.tiposComprobantes = tiposComp.filter(x => x.tipo === "D");
      } else {
        this.enabledFilterNroLote = true;
        this.tiposComprobantes = [];
      }
      if (this.nroLote) {
        this.enabledFiltersComprobantes = false;
        this.tipoComprobanteSelected = null;
        this.sucursal = null;
        this.nroComprobante = null;
      } else this.enabledFiltersComprobantes = true;
    },
    setComprobantesCobros(comprobantesGenerados) {
      if (this.registrosCobros.length > 0) {
        comprobantesGenerados.forEach(y => {
          const findRegistroDuplicado = this.registrosCobros.find(
            x => x.compId === y.compId
          );
          if (findRegistroDuplicado == null) this.registrosCobros.push(y);
          else {
            this.registrosCobros = this.registrosCobros.filter(
              x => x.compId != findRegistroDuplicado.compId
            );
            this.registrosCobros.push(y);
          }
          if (!y.numeradorAutomatico) this.disabledGenerarCobranzaBtn = true;
        });
      } else {
        this.registrosCobros = comprobantesGenerados;
        const findRegistroInvalid = comprobantesGenerados.find(
          x => x.numeradorAutomatico === false
        );
        if (findRegistroInvalid != null) this.disabledGenerarCobranzaBtn = true;
      }
      this.setAlert({
        type: "success",
        message: "Comprobante/s cargado/s con éxito."
      });
      this.loading = false;
    },
    quitarComprobante(id) {
      this.titleDelete = "¿Quitar comprobante?";
      this.deleteAllComps = false;
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmQuitarComprobante() {
      if (this.deleteAllComps) {
        this.loadingDeleteAllBtn = true;
        const response = await this.deleteAllCompsRegistro({
          sessionId: this.session
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({
            type: "success",
            message: "Todos los comprobantes han sido eliminados con éxito."
          });
          this.registrosCobros = [];
          this.deleteAllComps = false;
          this.disabledGenerarCobranzaBtn = false;
          this.loadingDeleteAllBtn = false;
        }
      } else {
        const response = await this.deleteCompRegistro({
          compId: this.idToDelete,
          sessionId: this.session
        });
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Quitado con éxito." });
          this.registrosCobros = this.registrosCobros.filter(
            x => x.compId !== this.idToDelete
          );
          const findRegistroInvalid = this.registrosCobros.find(
            x => x.numeradorAutomatico === false
          );
          if (findRegistroInvalid != null)
            this.disabledGenerarCobranzaBtn = true;
          else this.disabledGenerarCobranzaBtn = false;
        }
      }
    },
    formatPrice(importe) {
      const formatPrice = new Intl.NumberFormat("es-AR");
      const importeFormatted = formatPrice.format(importe);
      return importeFormatted;
    },
    async generarCobranza() {
      this.loadingGenerarCobranzaBtn = true;
      const response = await this.generarComprobantePago({
        sessionId: this.session
      });
      if (response.status === 200) {
        this.loadingGenerarCobranzaBtn = false;
        this.setAlert({
          type: "success",
          message: "Comprobante/s de cobro generado/s con éxito."
        });
        this.resultsExportExcel = response.data[0].table;
        const deleteComps = await this.deleteAllCompsRegistro({
          sessionId: this.session
        });
        if (deleteComps.status === 200) this.registrosCobros = [];
        this.modalExportarExcel = true;
      }
    },
    deleteAllComprobantes() {
      this.deleteAllComps = true;
      this.titleDelete = "¿Quitar comprobantes?";
      this.showDeleteModal = true;
    },
    exportExcel() {
      let result = [];
      this.resultsExportExcel.forEach(x =>
        result.push({
          ["AgenteCuentaId"]: x.agenteCuentaId,
          ["AgenteCuentaNom"]: x.agenteCuentaNom,
          ["Comprobante de deuda"]: x.comprobanteDeuda,
          ["Comprobante cancelatorio"]: x.comprobanteCancelatorio,
          ["Importe del comprobante de cobro"]: x.importeComprobanteCancelatorio,
          ["Error"]: x.decripcionError
        })
      );
      let formato = {
        filas: result,
        hoja: "Resultado"
      };
      helpersExcel.excelExport(formato, "Cobros masivos");
      this.toggleModalExportarExcel();
    },
    // toggles
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    toggleModalBuscarDeuda() {
      this.modalBuscarDeuda = !this.modalBuscarDeuda;
      this.loading = false;
    },
    toggleModalProcesoDeCobranza() {
      this.modalProcesoDeCobranza = !this.modalProcesoDeCobranza;
      this.text = "BuscarDeuda";
      this.tabBuscarDeuda = true;
      this.buscarDeudaTabActivate = true;
    },
    toggleFiltersSelected() {
      this.customizeFiltersApplied();
      this.showFilters = !this.showFilters;
    },
    toggleModalExportarExcel() {
      this.modalExportarExcel = !this.modalExportarExcel;
    },
  }
};
</script>

<style>
.fontColor {
  font-weight: bold !important;
  color: rgb(0, 0, 0) !important;
  text-align: center;
}
.invalidConfigText {
  color: red;
}
.no-upper-case {
  text-transform: none;
}
</style>
